import React from "react";
import TrombinoscopeLogo from "../../../assets/icons/trombinoscope.svg";
import FilterBar from "./FilterBar";
import FilterMenu from "./FilterMenu";
import ColumnOptions from "./ColumnOptions";
import PromotionSelector from "./PromotionSelector";
import { OrderButton } from "./OrderButton";
import { Student } from "./StudentInterface";
import {useAuth} from "react-oidc-context";
import {getHighestRole} from "../../../pages/Syllabus";
import {Group} from "../../../interfaces/AuthentificationInterface";
import {useNavigate} from "react-router-dom";

interface HeaderProps {
  viewMode: string;
  setViewMode: (mode: string) => void;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  columns: { key: string; label: string; visible: boolean }[];
  toggleColumnVisibility: (key: string) => void;
  filters: {
    label: string;
    options: string[];
    selectedOptions: string[];
    onChange: (selected: string[]) => void;
  }[];
  selectedPromotion: number;
  setSelectedPromotion: (promotion: number) => void;
  showPromotionSelector: boolean;
  sortCriterion: keyof Student | null;
  setSortCriterion: (criterion: keyof Student | null) => void;
}

export default function Header({
    viewMode,
    setViewMode,
    searchTerm,
    setSearchTerm,
    columns,
    toggleColumnVisibility,
    filters,
    selectedPromotion,
    setSelectedPromotion,
    showPromotionSelector,
    sortCriterion,
    setSortCriterion
}: HeaderProps) {
    const auth = useAuth();
    const role = getHighestRole(auth.user?.profile["group"] as Array<Group>);
    const navigate = useNavigate();

    const resetAllFiltersAndOptions = () => {
        setSearchTerm("");
        setSortCriterion(null);
        filters.forEach((filter) => filter.onChange([]));
        columns.forEach((col) => (col.visible = true));
    };

    return (
        <>
            <div className="page-header d-flex align-items-center p-1 justify-content-between rounded bg-light">
                {showPromotionSelector && (
                    <PromotionSelector
                        selectedPromotion={selectedPromotion}
                        setSelectedPromotion={(promotion: number) => {
                            setSelectedPromotion(promotion);
                            resetAllFiltersAndOptions();
                        }}
                        showSelector={showPromotionSelector}
                    />
                )}
                <h1 className="position-absolute start-50 m-auto">Trombinoscope</h1>
                <img
                    src={TrombinoscopeLogo}
                    alt="Trombinoscope logo"
                    className="ms-auto"
                    style={{ width: "40px" }}
                />
            </div>

            <div className="d-flex align-items-center mt-2 gap-3 px-3">
                <button
                    className="btn btn-light"
                    onClick={() =>
                        setViewMode(viewMode === "table" ? "gallery" : "table")
                    }
                >
                    {viewMode === "table" ? "Galerie" : "Tableau"}
                </button>

                {viewMode === "table" && (
                    <ColumnOptions
                        columns={columns}
                        toggleColumnVisibility={toggleColumnVisibility}
                    />
                )}
                {(role === Group.MajorLeader || role === Group.Teacher) && (
                    <button
                        className="btn btn-light"
                        onClick={() =>
                            navigate(`/trombinoscope/export?promotion=${selectedPromotion}`)
                        }
                    >
                       Création
                    </button>
                )}
                <div className="flex-grow-1">
                    <FilterBar searchTerm={searchTerm} setSearchTerm={setSearchTerm}/>
                </div>

                <FilterMenu filters={filters}/>

                <OrderButton
                    sortCriterion={sortCriterion}
                    setSortCriterion={setSortCriterion}
                />
            </div>
        </>
    );
}

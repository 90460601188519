import React, { useState } from 'react';
import { useAuth } from "react-oidc-context";
import { toast } from 'react-toastify';
import { StudentInfo } from '../../../../interfaces/StudentInfo';
import { UserService } from '../../../../services/UserService';

function ChangeUserInfoForm({ studentInfo }: { studentInfo: StudentInfo }) {
    const auth = useAuth();
    const [formData, setFormData] = useState<{
        [key: string]: string;
    }>({
        groupNames: studentInfo?.groupName ?? '',
        linkedin: studentInfo?.linkedin ?? '',
        phoneNumber: studentInfo?.phoneNumber ?? '',
        abroadSemester: studentInfo?.abroadSemester ?? 'wont',
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (
            formData.linkedin === '' ||
            formData.phoneNumber === '' ||
            formData.abroadSemester === ''
        ) {
            toast.error('Les champs LinkedIn et Numéro de téléphone doivent être remplis');
            return;
        } else if (!formData.linkedin.startsWith('https://www.linkedin.com/')) {
            toast.error('Le lien LinkedIn doit commencer par https://www.linkedin.com/');
            return;
        }
        try {
            UserService.UpdateStudentAttributes(
                auth,
                studentInfo.login || '',
                {
                    groupName: formData.groupNames ?? '',
                    linkedin: formData.linkedin,
                    phoneNumber: formData.phoneNumber,
                    abroadSemester: formData.abroadSemester,
                }
            ).then(() => {
                toast.success('Informations modifiées avec succès');
                window.location.reload();
            });
        }
        catch (error) {
            toast.error('Erreur lors de la modification de vos informations');
        }
    };

    const formNumberInputFields = [
        { name: 'groupNames', label: 'Nom du groupe', type: 'text' },
        { name: 'linkedin', label: 'LinkedIn', type: 'text' },
        { name: 'phoneNumber', label: 'Numéro de téléphone', type: 'text' }
    ];

    return (
        <>
            <form className="w-100" onSubmit={handleSubmit}>
                {formNumberInputFields.map((field) => (
                    <div className="form-group mb-2" key={field.name}>
                        <label htmlFor={field.name}>{field.label}</label>
                        <input
                            type={field.type}
                            className="form-control"
                            id={field.name}
                            name={field.name}
                            value={formData[field.name]}
                            onChange={handleInputChange} />
                    </div>
                ))}
                <div className="form-group mb-2">
                    <label htmlFor="abroadSemester">Semestre à l&apos;étranger</label>
                    <br />
                    <select
                        id="abroadSemester"
                        name="abroadSemester"
                        value={formData.abroadSemester}
                        onChange={handleSelectChange}
                    >
                        <option value="want">Je veux partir à l&apos;étranger</option>
                        <option value="wont">Je ne veux pas partir à l&apos;étranger</option>
                        <option value="must">Je dois partir à l&apos;étranger</option>
                    </select>
                </div>
                <br />
                <button type="submit" className="btn btn-primary">
                    Soumettre
                </button>
            </form>
        </>
    );
}

export default ChangeUserInfoForm;

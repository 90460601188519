import React from "react";
import { Table } from "react-bootstrap";
import ReactDiffViewer from 'react-diff-viewer-continued';
import { Syllabus } from "../../../../interfaces/SyllabusInterface";
import { Course } from "../../../../interfaces/CourseInterface";
import { SyllabusEditProposition } from "../../../../interfaces/SyllabusEditPropositionInterface";

interface VolumeCourseInfoTableProps {
    syllabus: Syllabus;
    course: Course;
    editPropositionSyllabus?: SyllabusEditProposition;
}

const VolumeCourseInfoTable: React.FC<VolumeCourseInfoTableProps> = ({ syllabus, editPropositionSyllabus }) => {
    // Fonction pour afficher le diff entre deux valeurs
    const renderDiff = (label: string, originalValue: number | string, newValue?: number | string) => {
        if (newValue !== undefined && originalValue !== newValue) {
            return (
                <tr key={label}>
                    <td>{label}</td>
                    <td>
                        <ReactDiffViewer
                            oldValue={String(originalValue)}
                            newValue={String(newValue)}
                            splitView={false}
                            hideLineNumbers={true}
                            showDiffOnly={false}
                        />
                    </td>
                </tr>
            );
        }
        return (
            <tr key={label}>
                <td>{label}</td>
                <td>{originalValue}</td>
            </tr>
        );
    };

    return (
        <Table striped bordered hover>
            <tbody>
                {renderDiff('Volume horaire MOOC', syllabus.hoursMooc, editPropositionSyllabus?.hoursMooc)}
                {renderDiff('Volume horaire CM', syllabus.hoursCm, editPropositionSyllabus?.hoursCm)}
                {renderDiff('Volume horaire CM/TD', syllabus.hoursCmTd, editPropositionSyllabus?.hoursCmTd)}
                {renderDiff('Volume horaire TD', syllabus.hoursTd, editPropositionSyllabus?.hoursTd)}
                {renderDiff('Volume horaire Coaching', syllabus.hoursCoaching, editPropositionSyllabus?.hoursCoaching)}
                {renderDiff('Volume horaire Examen', syllabus.hoursExams, editPropositionSyllabus?.hoursExams)}
                {renderDiff('Volume horaire Travail Personnel', syllabus.hoursPersonalWork, editPropositionSyllabus?.hoursPersonalWork)}
                {renderDiff(
                    'Volume total Face-à-Face',
                    syllabus.hoursMooc + syllabus.hoursCm + syllabus.hoursCmTd + syllabus.hoursTd + syllabus.hoursCoaching,
                    editPropositionSyllabus
                        ? editPropositionSyllabus.hoursMooc + editPropositionSyllabus.hoursCm + editPropositionSyllabus.hoursCmTd + editPropositionSyllabus.hoursTd + editPropositionSyllabus.hoursCoaching
                        : undefined
                )}
                {renderDiff(
                    'Volume Horaire Total',
                    syllabus.hoursMooc + syllabus.hoursCm + syllabus.hoursCmTd + syllabus.hoursTd + syllabus.hoursCoaching + syllabus.hoursExams + syllabus.hoursPersonalWork,
                    editPropositionSyllabus
                        ? editPropositionSyllabus.hoursMooc + editPropositionSyllabus.hoursCm + editPropositionSyllabus.hoursCmTd + editPropositionSyllabus.hoursTd + editPropositionSyllabus.hoursCoaching + editPropositionSyllabus.hoursExams + editPropositionSyllabus.hoursPersonalWork
                        : undefined
                )}
            </tbody>
        </Table>
    );
};

export default VolumeCourseInfoTable;

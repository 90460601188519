import {AuthContextProps} from "react-oidc-context";
import {Group} from "../interfaces/AuthentificationInterface";
import {toast} from "react-toastify";
import axios from 'axios';
import {Filters, TableViewProps} from "../components/shared/trombinoscope/TableView";

export type ExportServicePayload = {
    promotion: string;
    login: string;
    filename: string;
    role: Group;
}

export type ExportFilterPayload = {
    body: any;
}


export class TrombiService {

    static createFilterPayload(body: Filters, options: TableViewProps): ExportFilterPayload {
        return {
            body: {
                filters: {
                    group: body.selectedGroups.length === 0 ? undefined : body.selectedGroups,
                    groupPAE: body.selectedPAEs.length === 0 ? undefined : body.selectedPAEs,
                    groupCEC: body.selectedCECs.length === 0 ? undefined : body.selectedCECs,
                    international: body.selectedInternationals.length === 0 ? undefined : body.selectedInternationals
                },
                options: Object.entries(options).reduce((acc, [key, value]) => {
                    if (value) {
                        // @ts-ignore
                        acc[key] = value;
                    }
                    return acc;
                }, {})
            }
        };
    }
    static async exportTrombi(auth: AuthContextProps, payload: ExportServicePayload) {
        const url = `${process.env.REACT_APP_SCOLARITE_API_URL}/export/${payload.promotion}/${payload.filename}/${payload.login}/${payload.role.toString()}`;
        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${auth.user?.access_token}`
                },
                responseType: 'blob'
            });

            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${payload.filename}.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            toast.error("Erreur lors du téléchargement du fichier.");
        }
    }
    static async importTrombi(auth: AuthContextProps, file: File, role: Group, isCEC?: boolean, isPAE?: boolean) {
        const url = `${process.env.REACT_APP_SCOLARITE_API_URL}/import/${role}`;
        if(isCEC) {
            url.concat('?isCEC=true');
        } else if(isPAE) {
            url.concat('?isPAE=true');
        }
        const formData = new FormData();
        formData.append('file', file);
        try {
            await axios.post(url, formData, {
                headers: {
                    Authorization: `Bearer ${auth.user?.access_token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            toast.success("Fichier importé avec succès.");
        } catch (error) {
            toast.error("Erreur lors de l'import du fichier.");
        }
    }
    static async getTrombi(auth: AuthContextProps, promotion: string, payload: any ) {
        const url = `${process.env.REACT_APP_SCOLARITE_API_URL}/export/${promotion}`;
        try {
            const response = await axios.post(url, payload.body, {
                headers: {
                    Authorization: `Bearer ${auth.user?.access_token}`
                },
                responseType: 'blob'
            });

            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `trombi-${promotion}.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            toast.error("Erreur lors du téléchargement du fichier.");
        }

    }
}

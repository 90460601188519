import React from "react";

interface FilterMenuProps {
  filters: {
    label: string;
    options: string[];
    selectedOptions: string[];
    onChange: (selected: string[]) => void;
  }[];
}

export default function FilterMenu({ filters }: FilterMenuProps) {
    const cleanAndSortOptions = (options: string[]) => {
        return options
            .filter((option) => option && option.trim() !== "Non renseigné")
            .sort((a, b) => {
                const numA = parseFloat(a);
                const numB = parseFloat(b);

                if (!isNaN(numA) && !isNaN(numB)) {
                    return numA - numB;
                }
                return a.localeCompare(b);
            });
    };
    
    return (
        <>
            <style>
                {`
                .dropdown-submenu {
                    position: relative;
                }

                .dropdown-submenu > .dropdown-menu {
                    top: 0;
                    right: 100%;
                    margin-top: -0.5rem;
                    display: none;
                }

                .dropdown-submenu:hover > .dropdown-menu {
                    display: block;
                    position: absolute;
                    z-index: 1050;
                }
                `}
            </style>

            <div className="dropdown">
                <button
                    className="btn btn-light dropdown-toggle"
                    type="button"
                    id="filterMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
            Filtrer par
                </button>
                <ul className="dropdown-menu" aria-labelledby="filterMenuButton">
                    {filters.map((filter) => {
                        const cleanedOptions = cleanAndSortOptions(filter.options);

                        return (
                            <li
                                key={filter.label}
                                className="dropdown-submenu position-relative"
                            >
                                <button className="dropdown-item" type="button">
                                    {filter.label}
                                </button>
                                <ul className="dropdown-menu">
                                    {cleanedOptions.length === 0 ? (
                                        <li className="dropdown-item text-muted">
                                        Aucun {filter.label.toLowerCase()} renseigné
                                        </li>
                                    ) : (
                                        cleanedOptions.map((option) => (
                                            <li key={option} className="dropdown-item">
                                                <label
                                                    className="d-flex align-items-center"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        checked={filter.selectedOptions.includes(option)}
                                                        onChange={() => {
                                                            const newSelected =
                                filter.selectedOptions.includes(option)
                                    ? filter.selectedOptions.filter(
                                        (o) => o !== option
                                    )
                                    : [...filter.selectedOptions, option];
                                                            filter.onChange(newSelected);
                                                        }}
                                                        className="me-2"
                                                    />
                                                    {option}
                                                </label>
                                            </li>
                                        ))
                                    )}
                                </ul>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>
    );
}

import React, { useContext, useEffect } from "react";
import CardComponent from "../components/Common/CardComponent";
import CourseInfoTable from "../components/Common/CourseInfoTable";
import { ViewContext } from "../../../pages/Syllabus";
import { SyllabusService } from "../../../services/SyllabusService";
import { useAuth } from "react-oidc-context";
import { Syllabus, SyllabusState } from "../../../interfaces/SyllabusInterface";
import { CourseService } from "../../../services/CourseService";
import { Course } from "../../../interfaces/CourseInterface";
import { Group } from "../../../interfaces/AuthentificationInterface";
import { toast } from "react-toastify";
import VolumeCourseInfoTable from "../components/Common/VolumeCourseInfoTable";
import { SyllabusEditProposition } from "../../../interfaces/SyllabusEditPropositionInterface";

function GeneralView() {
    const auth = useAuth();
    let [syllabus, setSyllabus] = React.useState<Syllabus>({} as Syllabus);
    let [course, setCourse] = React.useState({} as Course);
    let [syllabusEditProposition, setSyllabusEditProposition] = React.useState<SyllabusEditProposition>({} as SyllabusEditProposition);
    const { selectedSyllabusCourse, currentPromo, role } = useContext(ViewContext);
    const [studentPromotion] = React.useState<number>(
        auth.user?.profile["promotion"] as number
    );

    useEffect(() => {
        if (selectedSyllabusCourse === -1) return;
        const promotion =
        role === Group.Student ? studentPromotion.toString() : currentPromo;
        SyllabusService.GetLastSyllabusByCourseId(
            auth,
            selectedSyllabusCourse.toString(),
            promotion
        )
            .then((Syllabus) => {
                setSyllabus(Syllabus);
                // status filled is set when a syllabus is edited
                if (Syllabus.status == SyllabusState.filled && (role == Group.MajorLeader || role == Group.Teacher)) {
                    SyllabusService.GetEditPropositions(auth, selectedSyllabusCourse.toString(), promotion).then((editPropositionSyllabus) => {
                        setSyllabusEditProposition(editPropositionSyllabus);
                    }).catch(() => {
                        toast.error("Erreur lors de la récupération d'une modification de syllabus");
                    });
                }
            })
            .catch(() => {
                toast.error("Aucun syllabus trouvé pour ce cours.");
            });
        CourseService.GetCourseById(auth, selectedSyllabusCourse).then((Course) => {
            setCourse(Course);
        });
    }, [selectedSyllabusCourse]);

    if (selectedSyllabusCourse === -1) return <></>;

    return (
        <div>
            <div className="container my-2 p-2">
                <div className="rounded bg-light p-3 my-2">
                    <h3 className="bg-primary text-white text-center rounded py-2">
                        {course.courseName}
                    </h3>
                </div>
                <div className="d-flex justify-content-center">
                    <div className="p-3 card w-50">
                        <div className="my-2 d-flex justify-content-center">
                            {syllabus.status == SyllabusState.filled && (role == Group.MajorLeader || role == Group.Teacher) ? (
                                <CourseInfoTable
                                    syllabus={syllabus}
                                    course={course}
                                    editPropositionSyllabus={syllabusEditProposition}
                                ></CourseInfoTable>
                            ) : (
                                <CourseInfoTable
                                    syllabus={syllabus}
                                    course={course}
                                ></CourseInfoTable>
                            )}
                        </div>
                    </div>
                </div>

                <div className="card p-3 my-4 gap-4">
                    <div className="my-2">
                        {syllabus.status == SyllabusState.filled && (role == Group.MajorLeader || role == Group.Teacher) ? (
                            <CardComponent
                                label={"Résumé et contexte"}
                                content={syllabus.summary}
                                newContent={syllabusEditProposition.summary}
                            ></CardComponent>
                        ) : (
                            <CardComponent
                                label={"Résumé et contexte"}
                                content={syllabus.summary}
                            ></CardComponent>
                        )}
                    </div>

                    <div className="my-2">
                        {syllabus.status == SyllabusState.filled && (role == Group.MajorLeader || role == Group.Teacher) ? (
                            <CardComponent
                                label={"Acquis d'apprentissage"}
                                content={syllabus.outcomes}
                                newContent={syllabusEditProposition.outcomes}
                            ></CardComponent>
                        ) : (
                            <CardComponent
                                label={"Acquis d'apprentissage"}
                                content={syllabus.outcomes}
                            ></CardComponent>
                        )}
                    </div>

                    <div className="my-2">
                        {syllabus.status == SyllabusState.filled && (role == Group.MajorLeader || role == Group.Teacher) ? (
                            <CardComponent
                                label={"Plan du cours"}
                                content={syllabus.plan}
                                newContent={syllabusEditProposition.plan}
                            ></CardComponent>
                        ) : (
                            <CardComponent
                                label={"Plan du cours"}
                                content={syllabus.plan}
                            ></CardComponent>
                        )}
                    </div>

                    <div className="my-2">
                        {syllabus.status == SyllabusState.filled && (role == Group.MajorLeader || role == Group.Teacher) ? (
                            <CardComponent
                                label={"Format d'apprentissage"}
                                content={syllabus.learningFormat}
                                newContent={syllabusEditProposition.learningFormat}
                            ></CardComponent>
                        ) : (
                            <CardComponent
                                label={"Format d'apprentissage"}
                                content={syllabus.learningFormat}
                            ></CardComponent>
                        )}
                    </div>

                    <div className="d-flex flex-row justify-content-between my-2">
                        <div className="p-3 w-50 me-2">
                            {syllabus.status == SyllabusState.filled && (role == Group.MajorLeader || role == Group.Teacher) ? (
                                <CardComponent
                                    label={"Evaluation des acquis"}
                                    content={syllabus.aavs}
                                    newContent={syllabusEditProposition.aavs}
                                ></CardComponent>
                            ) : (
                                <CardComponent
                                    label={"Evaluation des acquis"}
                                    content={syllabus.aavs}
                                ></CardComponent>
                            )}
                        </div>

                        <div className="p-3 w-50 ms-2">
                            {syllabus.status == SyllabusState.filled && (role == Group.MajorLeader || role == Group.Teacher) ? (
                                <CardComponent
                                    label={"Calcul de la note"}
                                    content={syllabus.finalGradeCalcul}
                                    newContent={syllabusEditProposition.finalGradeCalcul}
                                ></CardComponent>
                            ) : (
                                <CardComponent
                                    label={"Calcul de la note"}
                                    content={syllabus.finalGradeCalcul}
                                ></CardComponent>
                            )}
                        </div>
                    </div>

                    <div className="d-flex flex-row justify-content-between my-2">
                        <div className="p-3 w-50 me-2">
                            {syllabus.status == SyllabusState.filled && (role == Group.MajorLeader || role == Group.Teacher) ? (
                                <CardComponent
                                    label={"Prérequis"}
                                    content={syllabus.prerequisites}
                                    newContent={syllabusEditProposition.prerequisites}
                                ></CardComponent>
                            ) : (
                                <CardComponent
                                    label={"Prérequis"}
                                    content={syllabus.prerequisites}
                                ></CardComponent>
                            )}
                        </div>

                        <div className="p-3 w-50 ms-2">
                            {syllabus.status == SyllabusState.filled && (role == Group.MajorLeader || role == Group.Teacher) ? (
                                <CardComponent
                                    label={"Bibliographie"}
                                    content={syllabus.bibliography}
                                    newContent={syllabusEditProposition.bibliography}
                                ></CardComponent>
                            ) : (
                                <CardComponent
                                    label={"Bibliographie"}
                                    content={syllabus.bibliography}
                                ></CardComponent>
                            )}
                        </div>
                    </div>

                    <div className="d-flex justify-content-center">
                        <div className="p-3 w-50">
                            <div className="my-2 d-flex justify-content-center">
                                {syllabus.status == SyllabusState.filled && (role == Group.MajorLeader || role == Group.Teacher) ? (
                                    <VolumeCourseInfoTable
                                        syllabus={syllabus}
                                        course={course}
                                        editPropositionSyllabus={syllabusEditProposition}
                                    ></VolumeCourseInfoTable>
                                ) : (
                                    <VolumeCourseInfoTable
                                        syllabus={syllabus}
                                        course={course}
                                    ></VolumeCourseInfoTable>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GeneralView;

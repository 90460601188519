import React, { useState } from 'react';
import {toast} from "react-toastify";
import {Button} from "react-bootstrap";
import {useAuth} from "react-oidc-context";
import {TrombiService} from "../../services/TrombiService";
import {Group} from "../../interfaces/AuthentificationInterface";
import {getHighestRole} from "../../pages/Syllabus";

function FileUploadFormGroup(props: { CEC: boolean, PAE: boolean }) {
    const [currentFile, setCurrentFile] = useState<File>();
    const auth = useAuth();
    const role = getHighestRole(auth.user?.profile["group"] as Array<Group>);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        const selectedFiles = files as FileList;
        setCurrentFile(selectedFiles?.[0]);
    };


    const uploadFile = async () => {
        if (!currentFile) {
            toast.error("Sélectionner un fichier Excel valide");
            return;
        }

        const formData = new FormData();
        formData.append('file', currentFile);
        toast.info("Uploading file...");
        if(props.CEC) {
            await TrombiService.importTrombi(auth, currentFile, role, true, false);
        } else if (props.PAE) {
            await TrombiService.importTrombi(auth, currentFile, role, false, true);
        } else {
            await TrombiService.importTrombi(auth, currentFile, role);
        }
    };

    return (
        <div className="d-flex">
            <input type="file" accept=".xlsx" onChange={handleFileChange} className="bg-primary text-white d-flex justify-content-center align-items-center p-2 m-3"
                style={{borderRadius: '5px', width: '15vw'}}/>
            <Button onClick={uploadFile} className="bg-primary text-white d-flex justify-content-center align-items-center text-center p-2 m-3"
                style={{borderRadius: '5px', width: '15vw'}}>Importer la liste des groupes</Button>
        </div>
    );
}

export default FileUploadFormGroup;

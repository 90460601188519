import React from "react";
import { Table } from "react-bootstrap";
import ReactDiffViewer from 'react-diff-viewer-continued';
import { Syllabus } from "../../../../interfaces/SyllabusInterface";
import { Course } from "../../../../interfaces/CourseInterface";
import { SyllabusEditProposition } from "../../../../interfaces/SyllabusEditPropositionInterface";

interface CourseInfoTableProps {
    syllabus: Syllabus;
    course: Course;
    editPropositionSyllabus?: SyllabusEditProposition;
}

const CourseInfoTable: React.FC<CourseInfoTableProps> = ({ syllabus, course, editPropositionSyllabus }) => {
    // Fonction pour afficher le diff entre deux valeurs
    const renderDiff = (label: string, originalValue: string | number, newValue?: string | number) => {
        // this function can take too much types as parameters, so i used any to not list them all
        const renderCodeFoldMessage = (totalFoldedLines: any, expandUnchanged: any) => (
            <div>
                <span style={{ fontWeight: 'normal', textDecoration: 'none' }}>Aucun changement. </span>
                <span
                    onClick={expandUnchanged}
                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                >
                Cliquez pour afficher {totalFoldedLines} {totalFoldedLines > 1 ? 'lignes masquées' : 'ligne masquée'}
                </span>
            </div>
        );
        if (newValue !== undefined && originalValue !== newValue) {
            return (
                <tr key={label}>
                    <td>{label}</td>
                    <td>
                        <ReactDiffViewer
                            oldValue={String(originalValue)}
                            newValue={String(newValue)}
                            splitView={false}
                            hideLineNumbers={true}
                            showDiffOnly={false}
                            codeFoldMessageRenderer={renderCodeFoldMessage}
                        />
                    </td>
                </tr>
            );
        }
        return (
            <tr key={label}>
                <td>{label}</td>
                <td>{originalValue}</td>
            </tr>
        );
    };

    return (
        <Table striped bordered hover>
            <tbody>
                <tr>
                    <td>Nom</td>
                    <td>{course.courseName}</td>
                </tr>
                <tr>
                    <td>Semestre</td>
                    <td>{course.semester}</td>
                </tr>
                <tr>
                    <td>Semestre</td>
                    <td>{syllabus.ueCoef}</td>
                </tr>
                {renderDiff('Langue', syllabus.language, editPropositionSyllabus?.language)}
                <tr>
                    <td>Référent</td>
                    <td>{course.teachers?.[0]?.teacherLogin ?? ''}</td>
                </tr>
            </tbody>
        </Table>
    );
};

export default CourseInfoTable;

import React from "react";

interface PromotionSelectorProps {
  selectedPromotion: number;
  setSelectedPromotion: (promotion: number) => void;
  showSelector: boolean;
}

export default function PromotionSelector({
    selectedPromotion,
    setSelectedPromotion,
    showSelector,
}: PromotionSelectorProps) {
    if (!showSelector) return null;

    const nextYear = new Date().getFullYear() + 1;
    const promotions = [];
    for (let i = nextYear; i >= 2025; i--) {
        promotions.push(i);
    }

    return (
        <div className="dropdown mx-3">
            <button
                className="btn btn-light dropdown-toggle"
                type="button"
                id="promotionDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                {selectedPromotion}
            </button>
            <ul className="dropdown-menu" aria-labelledby="promotionDropdown">
                {promotions.map((promotion) => (
                    <li key={promotion}>
                        <button
                            className={`dropdown-item ${
                                selectedPromotion === promotion ? "active" : ""
                            }`}
                            onClick={() => setSelectedPromotion(promotion)}
                        >
                            {promotion}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
}

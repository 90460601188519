import React from "react";
import Presence from "../components/student/dashboard/presence/Presence";

function Dashboard() {
    return (
        <main
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}
        >
            <div className="col-md-6 col-xl-3">
                <Presence />
            </div>
        </main>
    );
}

export default Dashboard;

import React from "react";
import { Student } from "./StudentInterface";

interface OrderButtonProps {
  sortCriterion: keyof Student | null;
  setSortCriterion: (criterion: keyof Student | null) => void;
}

const getSortLabel = (criterion: keyof Student): string => {
    const sortLabels: { [key in keyof Student]?: string } = {
        group: "Équipe",
        groupPAE: "PAE",
        groupCEC: "CEC",
        abroadSemester: "International",
    };
    return sortLabels[criterion] || "Inconnu";
};


export function OrderButton({
    sortCriterion,
    setSortCriterion,
}: OrderButtonProps) {
    const handleSortChange = (criterion: keyof Student) => {
        setSortCriterion(criterion);
    };

    return (
        <div className="dropdown ms-2">
            <button
                className="btn btn-light dropdown-toggle"
                type="button"
                id="sortDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
        Trier {sortCriterion ? `(${getSortLabel(sortCriterion)})` : ""}
            </button>
            <ul className="dropdown-menu" aria-labelledby="sortDropdown">
                <li>
                    <button
                        className="dropdown-item"
                        onClick={() => handleSortChange("group")}
                    >
            Équipe
                    </button>
                </li>
                <li>
                    <button
                        className="dropdown-item"
                        onClick={() => handleSortChange("groupPAE")}
                    >
            PAE
                    </button>
                </li>
                <li>
                    <button
                        className="dropdown-item"
                        onClick={() => handleSortChange("groupCEC")}
                    >
            CEC
                    </button>
                </li>
                <li>
                    <button
                        className="dropdown-item"
                        onClick={() => handleSortChange("abroadSemester")}
                    >
            International
                    </button>
                </li>
            </ul>
        </div>
    );
}

import React, {useState} from 'react';
import {useAuth} from "react-oidc-context";
import {Group} from "../interfaces/AuthentificationInterface";
import {PromotionUtils} from "../utils/PromotionUtils";
import TrombiFormGroupView from "../components/Trombinoscope/TrombiFormGroupView";
import {getHighestRole} from "./Syllabus";
import { useSearchParams } from "react-router-dom";
export const ViewContext = React.createContext({
    role: Group.Student,
    login: "",
    currentPromo: PromotionUtils.getCurrentPromotion().toString(),
});

function TrombiExport() {
    const auth = useAuth();
    const userGroups = auth.user?.profile["group"] as Array<Group>;
    const [searchParams] = useSearchParams();

    const promotion = searchParams.get("promotion") || PromotionUtils.getCurrentPromotion().toString();

    const [role] = useState<Group>(getHighestRole(userGroups));
    const [currentPromo] = useState<string>(promotion);
    const [login] = useState<string>(auth.user?.profile["preferred_username"] as string);

    return (
        <ViewContext.Provider value={{role, currentPromo, login}}>
            <TrombiFormGroupView />
        </ViewContext.Provider>
    );
}

export default TrombiExport;

import React, {useContext, useEffect, useState} from "react";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {TrombiService} from "../../services/TrombiService";
import {AuthContextProps, useAuth} from "react-oidc-context";
import {Group} from "../../interfaces/AuthentificationInterface";
import {ViewContext} from "../../pages/Trombi";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import FileUploadFormGroup from "./FileUploadFormGroup";
import {TeacherService} from "../../services/TeacherService";

export default function TrombiFormGroupView() {
    const auth: AuthContextProps = useAuth();
    const promotion: string =  useContext(ViewContext).currentPromo;
    const role: Group = useContext(ViewContext).role;
    const login: string = useContext(ViewContext).login;
    const navigate = useNavigate();

    // This part is to handle the displaying of the CEC and PAE groups options in the selector
    const [isCeC, setIsCeC] = useState(false);
    const [isPaE, setIsPAE] = useState(false);

    const checkSelection = (): boolean => {
        const selectedValue = (document.getElementById("typeSelector") as HTMLSelectElement)?.value;
        if (selectedValue === "0") {
            toast.error("Veuillez sélectionner un type de groupe.");
            return false;
        }
        return true;
    };

    useEffect(() => {
        if(role === Group.Teacher) {
            TeacherService.getTeacher(login, auth).then((teacher) => {
                const filteredCourses = teacher.courses.filter(course => course.courseName === "CEC" || course.courseName === "PAE");
                setIsCeC(filteredCourses.some(course => course.courseName === "CEC"));
                setIsPAE(filteredCourses.some(course => course.courseName === "PAE"));
            });
        }
    },[login, role]);


    const exportTrombi = async () => {
        if(!checkSelection()) {
            return;
        }
        await TrombiService.exportTrombi(auth, {
            promotion: promotion,
            login: login,
            filename: "trombi-" + promotion,
            role: role
        });
    };

    const validate = async () => {
        navigate("/trombinoscope");
    };
    return (
        <Container fluid className="d-flex justify-content-center align-items-center m-5 rounded">
            <div className="bg-white position-relative p-5 rounded border-1" style={{ width: '50vw', height: '40vh' }}>
                <Row className="h-100">
                    <Col>
                        <Form.Select id="typeSelector" aria-label="Type Selector" className="mb-3"
                            style={{width: '50%', margin: '0 auto'}}>
                            {role === Group.Teacher && !isCeC && !isPaE ?
                                <option>Vous ne pouvez pas modifier les groupes de Pae, Cec ou SIGL</option>
                                : <option value="0">Sélectionner le type de groupe</option>}
                            {role === Group.MajorLeader && <option value="1">SIGL</option>}
                            {role === Group.Teacher && isCeC && <option value="2">CEC</option>}
                            {role === Group.Teacher && isPaE && <option value="3">PAE</option>}
                        </Form.Select>
                        <div className="d-flex flex-column align-items-center">
                            <Button onClick={exportTrombi} className="bg-primary text-white d-flex justify-content-center align-items-center text-center p-2 m-3"
                                style={{borderRadius: '5px', width: '25vw'}}>
                                    Exporter le trombinoscope
                            </Button>
                            <FileUploadFormGroup CEC={isCeC} PAE={isPaE}/>
                        </div>
                        <Button onClick={validate} variant="primary" className="position-absolute bottom-0 start-0 m-3">
                            Valider
                        </Button>
                    </Col>
                </Row>
            </div>
        </Container>
    );
}

import React from 'react';
import ReactDiffViewer from 'react-diff-viewer-continued';

interface CardComponentProps {
  label: string;
  content: string;
  newContent?: string;
}

const CardComponent: React.FC<CardComponentProps> = ({ label, content, newContent }) => {
    // Remplace les sauts de ligne par des balises <br /> pour l'ancien contenu
    if (content == null) {
        content = '';
    }
    const formattedContent = content.replace(/\n/g, '<br />');

    // this function can take too much types as parameters, so i used any to not list them all
    const renderCodeFoldMessage = (totalFoldedLines: any) => (
        <div>
            <span style={{ fontWeight: 'normal', textDecoration: 'none' }}>Aucun changement. </span>
            <span
                style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
            >
        Cliquez pour afficher {totalFoldedLines} {totalFoldedLines > 1 ? 'lignes masquées' : 'ligne masquée'}
            </span>
        </div>
    );

    return (
        <div className="card">
            <h5 className="card-header">{label}</h5>
            <div className="card-body">
                {newContent ? (
                    <ReactDiffViewer
                        oldValue={content}
                        newValue={newContent}
                        splitView={true} // Affichage côte à côte; passez à false pour un affichage en ligne
                        codeFoldMessageRenderer={renderCodeFoldMessage}
                    />
                ) : (
                    <p dangerouslySetInnerHTML={{ __html: formattedContent }}></p>
                )}
            </div>
        </div>
    );
};

export default CardComponent;

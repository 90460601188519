import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { UserService } from "../../../services/UserService";
import { StudentInfo } from "../../../interfaces/StudentInfo";
import { Modal, Button } from "react-bootstrap";
import ChangeUserInfoForm from "../components/forms/changeUserInfoForm";

import linkedinIcon from "../../../assets/icons/linkedin.svg";

function GeneralView() {
    const auth = useAuth();
    const userLogin = auth.user?.profile.preferred_username as string;
    const [userInfo, setUserInfo] = useState<StudentInfo>();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const response = UserService.GetStudentInfoByLogin(auth, userLogin);
        response
            .then((data) => setUserInfo(data))
            .catch((error) => console.error(error));
    }, [auth]);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
        <div className="container my-4">
            <div className="card shadow rounded-lg">
                <div className="card-header bg-primary text-white text-center">
                    <h3 className="mb-0">
                        {userInfo?.firstName} {userInfo?.lastName}
                    </h3>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="p-3 border rounded shadow-sm bg-light">
                                <h5 className="text-secondary">Informations personnelles</h5>
                                <p>
                                    <strong>Prénom :</strong> {userInfo?.firstName}
                                </p>
                                <p>
                                    <strong>Nom :</strong> {userInfo?.lastName}
                                </p>
                                <p>
                                    <strong>Adresse mail :</strong> {userInfo?.email}
                                </p>
                                <p>
                                    <strong>Numéro de téléphone :</strong> {userInfo?.phoneNumber}
                                </p>
                                <p>
                                    <strong>LinkedIn : </strong> 
                                    <span data-toggle="tooltip" title={"Renseignez le lien complet de votre profil LinkedIn."}>
                                        {userInfo?.linkedin ? (
                                            <a href={userInfo.linkedin} target="_blank" rel="noopener noreferrer">
                                                <img src={linkedinIcon} alt="LinkedIn" style={{ width: "24px", height: "24px" }} />
                                            </a>
                                        ) : (
                                            <span>Non renseigné</span>
                                        )}
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="p-3 border rounded shadow-sm bg-light">
                                <h5 className="text-secondary">Informations scolaires</h5>
                                <p>
                                    <strong>Promotion :</strong> {userInfo?.promotion}
                                </p>
                                <p>
                                    <strong>Groupe :</strong> {userInfo?.group}
                                </p>
                                <p>
                                    <strong>Nom du groupe SIGL :</strong> {userInfo?.groupName}
                                </p>
                                <p>
                                    <strong>Groupe CEC :</strong> {userInfo?.groupCEC}
                                </p>
                                <p>
                                    <strong>Groupe PAE :</strong> {userInfo?.groupPAE}
                                </p>
                                <p>
                                    <strong>Semestre à l&apos;étranger :</strong> 
                                    {userInfo?.abroadSemester === "want" ? (
                                        <span> Je veux partir à l&apos;étranger</span>
                                    ) : userInfo?.abroadSemester === "must" ? (
                                        <span> Je dois partir à l&apos;étranger</span>
                                    ) : (
                                        <span> Je ne veux pas partir à l&apos;étranger</span>
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                    {userInfo && (
                        <div className="text-center mt-3">
                            <Button variant="primary" onClick={handleShowModal}>
                                Modifier les informations
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Modifier les informations</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {userInfo && <ChangeUserInfoForm studentInfo={userInfo} />}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default GeneralView;

import React from "react";
import { FaSearch } from "react-icons/fa";

interface FilterBarProps {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
}

export default function FilterBar({
    searchTerm,
    setSearchTerm,
}: FilterBarProps) {
    return (
        <div className="container mt-3">
            <div className="input-group mb-3">
                <span className="input-group-text" id="search-icon">
                    <FaSearch />
                </span>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Entrez le prénom, nom ou email d'un élève"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
        </div>
    );
}

import React, { useState } from "react";
import { Student } from "./StudentInterface";

interface GalleryViewProps {
  students: Student[];
  searchTerm: string;
  sortCriterion: keyof Student | null;
  filters: {
    selectedGroups: string[];
    selectedPAEs: string[];
    selectedCECs: string[];
    selectedInternationals: string[];
  };
}

export default function GalleryView({
    students,
    searchTerm,
    sortCriterion,
    filters,
}: GalleryViewProps) {
    const [currentPage, setCurrentPage] = useState(1);
    const studentsPerPage = 15;

    const filteredStudents = students.filter((student) => {
        const searchFilter =
      student.login.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.email.toLowerCase().includes(searchTerm.toLowerCase());

        const groupFilter =
      filters.selectedGroups.length === 0 ||
      filters.selectedGroups.includes(student.group.toString());
        const paeFilter =
      filters.selectedPAEs.length === 0 ||
      filters.selectedPAEs.includes(student.groupPAE);
        const cecFilter =
      filters.selectedCECs.length === 0 ||
      filters.selectedCECs.includes(student.groupCEC);
        const internationalFilter =
      filters.selectedInternationals.length === 0 ||
      filters.selectedInternationals.includes(student.abroadSemester);

        return (
            searchFilter &&
      groupFilter &&
      paeFilter &&
      cecFilter &&
      internationalFilter
        );
    });

    if (sortCriterion) {
        filteredStudents.sort((a, b) => {
            const aValue = a[sortCriterion];
            const bValue = b[sortCriterion];

            if (aValue == null && bValue == null) return 0;
            if (aValue == null) return 1;
            if (bValue == null) return -1;

            if (aValue < bValue) return -1;
            if (aValue > bValue) return 1;
            return 0;
        });
    }

    const indexOfLastStudent = currentPage * studentsPerPage;
    const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
    const currentStudents = filteredStudents.slice(
        indexOfFirstStudent,
        indexOfLastStudent
    );

    const totalPages = Math.ceil(filteredStudents.length / studentsPerPage);

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className="container d-flex flex-column">
            <div className="row">
                {currentStudents.map((student, index) => (
                    <div key={index} className="col-md-2 mb-3" style={{ width: "250px" }}>
                        <div className="card">
                            <img
                                src={
                                    student.profile
                                        ? student.profile
                                        : `https://ui-avatars.com/api/?name=${
                                            student.firstName + " " + student.lastName
                                        }&background=0D8ABC&color=fff`
                                }
                                className="card-img-top"
                                alt={student.login}
                                style={{ height: "120px", objectFit: "cover" }}
                            />
                            <div className="card-body p-2" style={{ height: "150px" }}>
                                <h5 className="card-title fs-6">
                                    {student.firstName + " " + student.lastName}
                                </h5>
                                <p className="card-text mb-1">
                                    <small>{student.email}</small>
                                </p>
                                <p className="card-text mb-1">
                                    <small>{student.phoneNumber}</small>
                                </p>
                                <p className="card-text mb-0 text-center mt-3">
                                    <span className="badge bg-info">
                                        {student.group
                                            ? "Équipe " + student.group
                                            : "Équipe non définie"}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <nav className="d-flex justify-content-center mt-3">
                <ul className="pagination pagination-sm">
                    <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                        <button
                            className="page-link"
                            onClick={() => handlePageChange(currentPage - 1)}
                        >
              &laquo;
                        </button>
                    </li>
                    {Array.from({ length: totalPages }, (_, i) => (
                        <li
                            key={i + 1}
                            className={`page-item ${currentPage === i + 1 ? "active" : ""}`}
                        >
                            <button
                                className="page-link"
                                onClick={() => handlePageChange(i + 1)}
                            >
                                {i + 1}
                            </button>
                        </li>
                    ))}
                    <li
                        className={`page-item ${
                            currentPage === totalPages ? "disabled" : ""
                        }`}
                    >
                        <button
                            className="page-link"
                            onClick={() => handlePageChange(currentPage + 1)}
                        >
              &raquo;
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

import {Teacher} from "../interfaces/TeacherInterface";
import axios from "axios";
import {AuthContextProps} from "react-oidc-context";

const API_URL = process.env.REACT_APP_SCOLARITE_API_URL;

export class TeacherService {
    static async getTeacher(login: string, auth: AuthContextProps): Promise<Teacher> {
        try {
            const response = await axios.get(`${API_URL}/teacher/${login}`,
                { headers: { Authorization: `Bearer ${auth.user?.access_token}` } });
            return response.data;
        } catch (error) {
            console.error('Error getting teacher:', error);
            throw new Error('Failed to get teacher');
        }
    }
}
